import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import BookList from './BookList'

const BooksGrid = () => {

  const { allChristianBook } = useStaticQuery(
    graphql`
  query {
    allChristianBook {
      nodes {
        id
        drupal_internal__nid
        title
        relationships {
          author {
            title
          }
          chapters {
            chapter_number
            chapter_title
            id
            body {
              processed
            }
          }
          image {
            localFile {
              publicURL
              childImageSharp {
                fluid(maxWidth: 120, maxHeight: 180) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
  )


  return (
    <div className="book__list--wrapper">
      {
        allChristianBook.nodes
          ? <BookList list={allChristianBook.nodes} rootUrl={'book'} />
          : <p>No Books yet, check back later! </p>
      }
    </div>
  )
}

export default BooksGrid;
