import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby';

import '../../sass/base/_bgs.scss';


const BookList = ({ list, rootUrl }) => {
  const bgClasses = ['jigsaw', 'motion-lines', 'autumn', 'stamp', 'anchor', 'crosses', 'leaf'];
  const randomBg = bgClasses[Math.floor(Math.random() * bgClasses.length)];

  const items = list.map(({ drupal_internal__nid, title, relationships }) => {
    console.log(relationships);
    const { author } = relationships;

    // Create slug from title by replacing spaces with dashes then delete non-word
    // chars except hyphens and turn to lowercase. 
    const transformedTitle = title.replace(/\s+/g, '-').replace(/[^a-zA-Z-]/g, '').toLowerCase();
    const slug = `${transformedTitle}-${drupal_internal__nid}`;

    return (
      <div className="book__list--item">
        <Link key={drupal_internal__nid} className="book--bg--wrapper" to={`/${rootUrl}/${slug}/`}>
          <div className={`book--bg ${randomBg}`}>
            <div className="book--item__title">
              {title}
              <div className="book--item__author">By {author.title}</div>
            </div>

          </div>
        </Link>
      </div>
    )
  })

  return (
    <div className="container book__list">
      {items}
    </div>
  )

}

export default BookList;
