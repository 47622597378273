import React from "react"
import Layout from "../components/layout"

import BooksGrid from '../components/books/BooksGrid'
import AuthorGrid from '../components/authors/AuthorGrid'

export default function Home() {
  return (
    <Layout>
      <section id="books" className="home--section">
        <h2>Books</h2>
        <BooksGrid />
      </section>

      <section id="authors" className="home--section">
        <h2>Authors</h2>
        <AuthorGrid />
      </section>

    </Layout>
  )
}
