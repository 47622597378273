import React from "react"
import { useStaticQuery, graphql } from 'gatsby'

import List from '../List'
import './authors.scss'

const AuthorGrid = () => {
  const { allAuthors } = useStaticQuery(
    graphql`
      query {
        allAuthors {
          nodes {
            title
            bio {
              summary
            }
            drupal_internal__nid
            drupal_id
          }
        }
      }
    `
  )

  return (
    <div className="container author__list">
      {
        allAuthors.nodes
          ? <List list={allAuthors.nodes} rootUrl={'author'} />
          : <p>No Authors yet, check back later!</p>
      }
    </div>
  )
}

export default AuthorGrid
